"use client";

import React, { useEffect, useState } from "react";
import Widget from "./Widget";
import Image from "next/image";
import Link from "next/link";

interface NewsArticle {
  title: string;
  imageUrl: string;
  url: string;
}

const NewsWidget: React.FC = () => {
  const [news, setNews] = useState<NewsArticle[]>([]);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0); // Track which article to show
  const [isAnimating, setIsAnimating] = useState(false); // Control fade animation
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchNews = async () => {
    try {
      setIsLoading(true);
      const apiKey = process.env.NEXT_PUBLIC_NEWS_API_KEY;

      if (!apiKey) {
        throw new Error("Missing API key");
      }

      const response = await fetch(
        `https://newsapi.org/v2/everything?q=Japan&pageSize=5&apiKey=${apiKey}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch news data.");
      }

      const data = await response.json();
      const formattedNews = data.articles.map((article: any) => ({
        title: article.title,
        imageUrl: article.urlToImage || "https://via.placeholder.com/150",
        url: article.url,
      }));

      setNews(formattedNews);
      setError(null);
    } catch (err: any) {
      setError(err.message || "An error occurred while fetching news.");
      setNews([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  // Cycle through news articles every 5 seconds
  useEffect(() => {
    if (news.length === 0) return;

    const interval = setInterval(() => {
      setIsAnimating(true);

      setTimeout(() => {
        setCurrentNewsIndex((prevIndex) => (prevIndex + 1) % news.length); // Go to next article
        setIsAnimating(false);
      }, 400); // Match fade-out timing
    }, 5000);

    return () => clearInterval(interval);
  }, [news]);

  // Guard: If loading or no news available
  if (isLoading) {
    return (
      <Widget
        icon="https://storage.googleapis.com/photo-portfolio/tara-japan/news.png"
        title="Latest Japan News"
        subtitle="Fetching the latest headlines"
        content={<p className="text-sm text-gray-600">Loading news...</p>}
      />
    );
  }

  if (error) {
    return (
      <Widget
        icon="https://storage.googleapis.com/photo-portfolio/tara-japan/news.png"
        title="Latest Japan News"
        subtitle="What's happening in Japan now"
        content={<p className="text-sm text-red-500">{error}</p>}
      />
    );
  }

  // Get the current article to display
  const currentNews = news[currentNewsIndex];

  return (
    <Widget
      icon="https://storage.googleapis.com/photo-portfolio/tara-japan/news.png"
      title="Latest Japan News"
      subtitle="What's happening in Japan now"
      content={
        <div className="flex gap-4 items-center transition-opacity duration-300 mx-2">
          {/* News Thumbnail */}
          <Image
            src={currentNews.imageUrl}
            alt="News Thumbnail"
            width={80}
            height={80}
            className={`rounded-md ${isAnimating ? "opacity-0" : "opacity-100"}`}
          />
          {/* News Headline */}
          <div className="text-left">
            <Link
              href={currentNews.url}
              target="_blank"
              rel="noopener noreferrer"
              className={`block text-sm underline hover:text-primary transition-opacity duration-300 ${isAnimating ? "opacity-0" : "opacity-100"
                }`}
            >
              {currentNews.title}
            </Link>
          </div>
        </div>
      }
    />
  );
};

export default NewsWidget;

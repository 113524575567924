type TypographyH1Props = {
  children: React.ReactNode;
  className?: string
};

export function TypographyH1({ children, className }: TypographyH1Props) {
  return (
    <h3 className={`scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl ${className || ''}`}>
      {children}
    </h3>
  );
}
"use client";

import Container from "./components/Container";
import Hero from "./components/Hero";
import VisitingJapanBlogSection from "./sections/VisitingJapanBlogSection";
import LivingInJapanBlogSection from "./sections/LivingInJapanBlogSection";
import HomePageBannerCarousel from "./sections/HomepageBannerCarousel";
import LivingInJapanGuide from "./sections/LivingInJapanGuide";
import VisitingJapanGuide from "./sections/VisitingJapanGuide";
import WidgetsSection from "./sections/Widgets";
import AboutTaraJapan from "./sections/AboutTaraJapan";


const HomePage: React.FC = () => {

  return (
    <>
      <Hero />
      <div className="flex items-center justify-center mx-auto">
        <WidgetsSection />
      </div>
      <Container large>
        <section id="visiting-japan">
          <VisitingJapanGuide />
          <HomePageBannerCarousel />
          <VisitingJapanBlogSection />
        </section>
        <section id="living-in-japan">
          <LivingInJapanGuide />
          <LivingInJapanBlogSection />
        </section>
        <section>
          <AboutTaraJapan />
        </section>
      </Container>
    </>
  );
};

export default HomePage;

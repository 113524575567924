"use client";

import Link from "next/link";
import { useMediaQuery } from "@/hooks/use-media.query";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import { MenuIcon } from "lucide-react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import UserAccountNav from "./UserAccountNav";
import Logo from "@/app/(website)/components/Logo";
import { useState, useRef, useEffect } from "react";

const Navbar = () => {
  const isDesktop = useMediaQuery("(min-width:768px)");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const showDropdown = () => setDropdownVisible(true);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navData = {
    gettingStarted: [
      { title: "About Tara Japan", href: "/pages/about" },
      { title: "Blogs", href: "/pages/blogs-all" },
      { title: "Forums", href: "/pages/forums-all" }
    ],
    livingInJapan: [
      {
        title: "Living in Japan as a Filipino",
        href: "/pages/living-in-japan",
        submenu: [
          { title: "Blogs", href: "/pages/blogs-living-in-japan" },
          { title: "Jobs", href: "/pages/jobs" },
          { title: "Forum", href: "/pages/forum-living-in-japan" },
          { title: "Seasonal Events", href: "/pages/seasonal-events" },
          { title: "Language and Culture", href: "/pages/language-and-culture" },
          { title: "Housing", href: "/pages/housing-and-rentals" },
          { title: "Business Directory", href: "/pages/business-directory" },
          { title: "Japan Latest News", href: "/pages/japan-latest-news" },
        ],
      },
    ],
    visitingJapan: [
      {
        title: "Visiting Japan",
        href: "/pages/visiting-japan",
        submenu: [
          { title: "Visa Guide", href: "/pages/visa-guide" },
          { title: "Visa Approval Rate Calculator", href: "/pages/visa-approval-calculator" },
          { title: "Package Tours", href: "/pages/package-tours" },
          { title: "Adventures", href: "/pages/adventures" },
          { title: "Budol Finds", href: "/pages/budol-finds" },
          { title: "Forums", href: "/forums-visiting-japan" },
          { title: "Hidden Gems", href: "/pages/hidden-gems" },
          { title: "Blogs", href: "/pages/blogs-visiting-japan" },
          { title: "Itinerary Suggestions", href: "/pages/itinerary-suggestions" },
        ],
      },
    ],
  };

  const mobileMenu = (
    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen} direction="left">
      <DrawerTrigger>
        <MenuIcon className="text-slate-400" onClick={toggleDrawer} />
      </DrawerTrigger>
      <DrawerContent>
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 p-2 bg-gray-200 rounded-full shadow-md hover:bg-gray-300"
          onClick={toggleDrawer}
        >
          Close
        </button>

        <div className="mt-20 space-y-4">
          {/* Render all navData sections dynamically */}
          {Object.entries(navData).map(([sectionKey, sectionItems]) => (
            <Accordion type="single" collapsible key={sectionKey}>
              <AccordionItem value={sectionKey}>
                {/* Section Title */}
                <AccordionTrigger className="capitalize">
                  {sectionKey.replace(/([A-Z])/g, " $1")}
                </AccordionTrigger>
                <AccordionContent>
                  {/* Map Section Items */}
                  {sectionItems.map((item) => (
                    <div key={item.title}>
                      <Link
                        href={item.href}
                        className="font-semibold block mt-2 hover:text-primary"
                        onClick={() => setIsDrawerOpen(false)} // Close drawer on click
                      >
                        {item.title}
                      </Link>
                      {item.submenu && (
                        <div className="ml-4 mt-2 space-y-1">
                          {item.submenu.map((subItem) => (
                            <Link
                              key={subItem.title}
                              href={subItem.href}
                              className="block text-sm py-2 hover:text-primary"
                              onClick={() => setIsDrawerOpen(false)} // Close drawer on click
                            >
                              {subItem.title}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ))}
        </div>
      </DrawerContent>
    </Drawer>
  );


  const desktopDropdown = (
    <div
      className="relative"
      onMouseEnter={showDropdown}
      ref={dropdownRef}
    >
      {/* Get Started Button */}
      <button
        className="text-sm font-medium px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
      >
        Get Started
      </button>

      {dropdownVisible && (
        <div
          className="absolute top-10 left-0 bg-white shadow-lg rounded-lg border w-[700px] p-6 z-50 grid grid-cols-3 gap-4"
        >
          {/* Getting Started Links */}
          <div>
            <h4 className="font-bold mb-2">Getting Started</h4>
            {navData.gettingStarted.map((item) => (
              <Link key={item.title} href={item.href} className="block mb-2 text-sm hover:text-primary">
                {item.title}
              </Link>
            ))}
          </div>

          {/* Visiting Japan Links */}
          <div>
            <h4 className="font-bold mb-2">Visiting Japan</h4>
            {navData.visitingJapan.map((item) => (
              <div key={item.title}>
                <Link href={item.href} className="font-semibold text-sm hover:text-primary">
                  {item.title}
                </Link>
                {item.submenu && (
                  <div className="ml-4 mt-2 space-y-1">
                    {item.submenu.map((subItem) => (
                      <Link key={subItem.title} href={subItem.href} className="block text-sm hover:text-primary">
                        {subItem.title}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Living in Japan Links */}
          <div>
            <h4 className="font-bold mb-2">Living in Japan</h4>
            {navData.livingInJapan.map((item) => (
              <div key={item.title}>
                <Link href={item.href} className="font-semibold text-sm hover:text-primary">
                  {item.title}
                </Link>
                {item.submenu && (
                  <div className="ml-4 mt-2 space-y-1">
                    {item.submenu.map((subItem) => (
                      <Link key={subItem.title} href={subItem.href} className="block text-sm hover:text-primary">
                        {subItem.title}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <nav className="sticky top-0 z-50 flex items-center py-4 justify-between w-full px-4 md:max-w-screen-xl md:mx-auto">
      {/* Left Menu */}
      <div className="flex items-center space-x-4">
        {isDesktop ? desktopDropdown : mobileMenu}
      </div>

      {/* Centered Logo */}
      <div className="flex justify-center w-full md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
        <Link href="/" aria-label="Home">
          <Logo className="h-8 w-auto" />
        </Link>
      </div>

      {/* User Account Navigation */}
      <div className="ml-auto">
        <UserAccountNav />
      </div>
    </nav>
  );
};

export default Navbar;

import React, { useEffect, useState } from "react";
import Widget from "./Widget";
import Image from "next/image";

const ExchangeRateWidget = () => {
  const [exchangeRates, setExchangeRates] = useState<{
    phpToJpy?: number;
    jpyToPhp?: number;
    lastUpdated?: string;
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const fetchExchangeRates = async () => {
    try {
      setIsLoading(true);

      const response = await fetch("/api/exchange");
      if (!response.ok) {
        throw new Error("Failed to fetch exchange rates");
      }

      const data = await response.json();
      setExchangeRates(data);
      setError("");
    } catch (err: any) {
      console.error("Error fetching exchange rates:", err.message);
      setError(err.message || "Failed to fetch exchange rates.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchExchangeRates();
  }, []);

  const content = isLoading ? (
    <div className="flex items-center justify-center py-2">
      <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-primary"></div>
    </div>
  ) : error ? (
    <p className="text-xs text-red-500 text-center">{error}</p>
  ) : (
    <div className="text-center mt-2">
      {/* PHP to JPY */}
      <div className="flex justify-center items-center gap-2 text-sm">
        <div className="flex items-center gap-2">
          <Image
            src="https://storage.googleapis.com/photo-portfolio/tara-japan/ph-flag-2.png"
            alt="Philippine Flag"
            width={30}
            height={30}
            className="rounded-full"
          />
          <span className="font-medium text-gray-700">1 PHP ➡️</span>
        </div>
        <span className="font-bold text-green-600">
          {exchangeRates.phpToJpy} JPY
        </span>
      </div>

      {/* JPY to PHP */}
      <div className="flex justify-center items-center gap-2 text-sm">
        <div className="flex items-center gap-2">
          <Image
            src="https://storage.googleapis.com/photo-portfolio/tara-japan/japan-flag-2.png"
            alt="Japan Flag"
            width={30}
            height={30}
            className="rounded-full"
          />
          <span className="font-medium text-gray-700">1 JPY ➡️</span>
        </div>
        <span className="font-bold text-primary">
          {exchangeRates.jpyToPhp} PHP
        </span>
      </div>
    </div>
  );

  return (
    <Widget
      icon="https://storage.googleapis.com/photo-portfolio/tara-japan/yen.png"
      title="PHP ↔ JPY"
      subtitle={`As of: ${exchangeRates.lastUpdated || "N/A"}`}
      content={content}
    />
  );
};

export default ExchangeRateWidget;

import {groq} from "next-sanity";

// Get all posts
export const postquery = groq`
*[_type == "post" && defined(publishedAt)] | order(publishedAt desc, _createdAt desc) {
  _id,
  _createdAt,
  publishedAt,
  mainImage {
    ...,
    "blurDataURL":asset->metadata.lqip,
    "ImageColor": asset->metadata.palette.dominant.background,
  },
  featured,
  excerpt,
  slug,
  title,
  author-> {
    _id,
    image,
    slug,
    name
  },
  categories[]->,
}
`;
// Get all posts with 0..limit
export const limitquery = groq`
*[_type == "post"] | order(publishedAt desc, _createdAt desc) [0..$limit] {
  ...,
  author->,
  categories[]->
}
`;
// [(($pageIndex - 1) * 10)...$pageIndex * 10]{
// Get subsequent paginated posts
export const paginatedquery = groq`
*[_type == "post"] | order(publishedAt desc, _createdAt desc) [$pageIndex...$limit] {
  ...,
  author->,
  categories[]->
}
`;

// Get Site Config
export const configQuery = groq`
  *[_type == "settings"][0] {
    title,
    url,
    copyright,
    "mainLogo": logo.asset->url,
    "mainLogoAlt": logo.alt,
    "alternateLogo": logoalt.asset->url,
    "alternateLogoAlt": logoalt.alt,
    email,
    phone,
    w3ckey,
    social[] {
      media,
      url
    },
    description,
    "openGraphImage": openGraphImage.asset->url,
    keywords
  }
`;

// Single Post
export const singlequery = groq`
*[_type == "post" && slug.current == $slug][0] {
  ...,
  body[]{
    ...,
    markDefs[]{
      ...,
      _type == "internalLink" => {
        "slug": @.reference->slug
      }
    }
  },
  author->,
  categories[]->,
  "estReadingTime": round(length(pt::text(body)) / 5 / 180 ),
  "related": *[_type == "post" && count(categories[@._ref in ^.^.categories[]._ref]) > 0 ] | order(publishedAt desc, _createdAt desc) [0...5] {
    title,
    slug,
    "date": coalesce(publishedAt,_createdAt),
    "image": mainImage
  },
}
`;

// Paths for generateStaticParams
export const pathquery = groq`
*[_type == "post" && defined(slug.current)][].slug.current
`;
export const catpathquery = groq`
*[_type == "category" && defined(slug.current)][].slug.current
`;
export const authorsquery = groq`
*[_type == "author" && defined(slug.current)][].slug.current
`;

// Get Posts by Authors
export const postsbyauthorquery = groq`
*[_type == "post" && $slug match author->slug.current ] {
  ...,
  author->,
  categories[]->,
}
`;

// Get Posts by Category
export const postsbycatquery = groq`
*[_type == "post" && $slug in categories[]->slug.current ] {
  ...,
  author->,
  categories[]->,
}
`;

// Get top 5 categories
export const catquery = groq`*[_type == "category"] {
  ...,
  "count": count(*[_type == "post" && references(^._id)])
} | order(count desc) [0...5]`;

export const searchquery = groq`*[_type == "post" && _score > 0]
| score(title match $query || excerpt match $query || pt::text(body) match $query)
| order(_score desc)
{
  _score,
  _id,
  _createdAt,
  mainImage,
  author->,
  categories[]->,
   title,
   slug
}`;

// Get all Authors
export const allauthorsquery = groq`
*[_type == "author"] {
 ...,
 'slug': slug.current,
}
`;

// get everything from sanity
// to test connection
export const getAll = groq`*[]`;


//fetch banner images
export const bannersQuery = groq`
*[_type == "banner"] | order(_createdAt desc) {
  _id,
  title,
  imgSrc,
  linkHref,
  alt
}
`;

// Fetch photos
export const photosQuery = (category) => groq`
  *[_type == "photo" && category == $category] | order(_createdAt desc) {
    _id,
    title,
    imgSrc,
    linkHref,
    alt
  }
`;

// Fetch icons
export const iconsQuery = groq`
  *[_type == "icon"]{
    _id,
    name,
    "iconUrl": iconImage.asset->url,
    iconImage {
      altText
    },
    link, 
    description,
    locationType
  }
`;

// Fetch all topics
export const topicsQuery = groq`
  *[_type == "topics"] | order(_createdAt desc) {
    _id,
    name,
    description,
    slug,
    icon->{
    "iconUrl": iconImage.asset->url,
    iconImage {
      altText
    }
  }
  }
`;

// search within the forum
export async function searchForum(query) {
  if (client) {
    try {
      const params = {query};
      const results = await client.fetch(forumSearchQuery, params);
      return results;
    } catch (error) {
      console.error("Error performing forum search:", error);
      return [];
    }
  }
  return [];
}

// Fetch forum topics based on slug
export const topicsBySlugQuery = groq`
  *[_type == "topics" && slug.current == $slug]{
    _id,
    name,
    description,
    slug,
    icon->{
      "iconUrl": iconImage.asset->url,
      iconImage {
        altText
      }
    }
  }
`;

// Generate all forum slugs for topics
export const allTopicsSlugsQuery = groq`
  *[_type == "topics"]{
    "slug": slug.current
  }
`;

export const pageHeroBySlugQuery = groq`
  *[_type == "pageHero" && slug.current == $slug][0] {
    title,
    description,
    backgroundMedia {
      type,
      image {
        asset->{
          url
        },
        altText
      },
      videoUrl
    }
  }
`;

export const allDirectoriesQuery = groq`
*[_type == "directory"] | order(_createdAt desc) {
  _id,
  _createdAt,
  name,
  description,
  businessType,
  contactInfo {
    phone,
    email
  },
  socialLinks {
    website,
    facebook,
    instagram,
    twitter,
    other
  },
  "iconUrl": icon.asset->url,
  categories[]-> {
    _id,
    title,
    color,
    description
  },
  region-> {
    _id,
    name
  },
  prefecture-> {
    _id,
    name,
    region-> {
      _id,
      name
    }
  }
}
`;

export const directoriesByCategoryQuery = groq`
*[_type == "directory" && $slug in categories[]->slug.current] | order(_createdAt desc) {
  _id,
  _createdAt,
  name,
  description,
  businessType,
  contactInfo {
    phone,
    email
  },
  socialLinks {
    website,
    facebook,
    instagram,
    twitter,
    other
  },
  "iconUrl": icon.asset->url,
  categories[]-> {
    _id,
    title,
    color,
    description,
    slug
  },
  region-> {
    _id,
    name
  },
  prefecture-> {
    _id,
    title,
    region-> {
      _id,
      name
    }
  }
}
`;





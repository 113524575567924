import React from "react";

interface TypographyPProps extends React.HTMLAttributes<HTMLParagraphElement> {
  children?: React.ReactNode;
}

export const TypographyP: React.FC<TypographyPProps> = ({
  children,
  ...props
}) => {
  return <p {...props}>{children}</p>;
};

"use client";

import Link from "next/link";
import NewsletterSignup from "../components/NewsLetterSignup";
import SocialLinks from "../components/SocialLinks";
import ContactInfo from "../components/ContactInfo";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200 py-10 mt-10">
      <div className="container mx-auto max-w-7xl space-y-2">
        {/* Top Legal Links */}
        <div className="flex flex-col md:flex-row items-start justify-between gap-8 mx-auto mb-6 max-w-7xl text-sm">
          <div className="md:w-1/2 flex justify-start">
            <NewsletterSignup />
          </div>

          <div className="md:w-1/4 flex justify-center">
            <SocialLinks />
          </div>

          <div className="md:w-1/4 flex justify-end">
            <ContactInfo />
          </div>
        </div>

        {/* Footer Links Section */}
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-8 text-sm pb-10">
          {/* Explore Japan */}
          <div>
            <h3 className="font-semibold mb-2">Explore Tara Japan</h3>
            <ul className="space-y-1">
              <li><Link href="/tours" className="hover:text-primary transition-colors">Visiting Japan</Link></li>
              <li><Link href="/tours/group" className="hover:text-primary transition-colors">Living in Japan</Link></li>
              <li><Link href="/tours/self-guided" className="hover:text-primary transition-colors">Self-Guided Tours</Link></li>
              <li><Link href="/tours/personalized" className="hover:text-primary transition-colors">Personalized Tours</Link></li>
            </ul>
          </div>

          {/* Plan Your Trip */}
          <div>
            <h3 className="font-semibold mb-2">Plan Your Trip</h3>
            <ul className="space-y-1">
              <li><Link href="/transport/japan-rail-pass" className="hover:text-primary transition-colors">Japan Rail Pass</Link></li>
              <li><Link href="/transport/tickets" className="hover:text-primary transition-colors">Train Reservations</Link></li>
              <li><Link href="/accommodation/house-rentals" className="hover:text-primary transition-colors">Hotel and Accommodation Guide</Link></li>
            </ul>
          </div>

          {/* Living in Japan */}
          <div>
            <h3 className="font-semibold mb-2">Living in Japan</h3>
            <ul className="space-y-1">
              <li><Link href="/living/jobs" className="hover:text-primary transition-colors">Job Opportunities</Link></li>
              <li><Link href="/living/healthcare" className="hover:text-primary transition-colors">Healthcare Services</Link></li>
              <li><Link href="/living/legal" className="hover:text-primary transition-colors">Legal Assistance</Link></li>
              <li><Link href="/living/banking" className="hover:text-primary transition-colors">Banking & Finance</Link></li>
              <li><Link href="/living/housing" className="hover:text-primary transition-colors">Housing Support</Link></li>
            </ul>
          </div>

          {/* Community */}
          <div>
            <h3 className="font-semibold mb-2">Community</h3>
            <ul className="space-y-1">
              <li><Link href="/community/events" className="hover:text-primary transition-colors">Filipino Events</Link></li>
              <li><Link href="/community/embassy" className="hover:text-primary transition-colors">Philippine Embassy</Link></li>
              <li><Link href="/community/organizations" className="hover:text-primary transition-colors">Community Organizations</Link></li>
              <li><Link href="/community/volunteering" className="hover:text-primary transition-colors">Volunteering</Link></li>
            </ul>
          </div>

          {/* About */}
          <div>
            <h3 className="font-semibold mb-2">About Tara Japan</h3>
            <ul className="space-y-1">
              <li><Link href="/pages/about" className="hover:text-primary transition-colors">Who We Are</Link></li>
              <li><Link href="/pages/contact" className="hover:text-primary transition-colors">Contact Us</Link></li>
              <li><Link href="/newsletter" className="hover:text-primary transition-colors">Newsletter Signup</Link></li>
              <li><Link href="/affiliates" className="hover:text-primary transition-colors">Affiliate Program</Link></li>
              <li><Link href="/privacy-policy" className="hover:text-primary transition-colors">Privacy Policy</Link></li>
            </ul>
          </div>
        </div>

        {/* Bottom Links */}
        <div className="flex flex-wrap justify-center gap-4 text-sm text-gray-600 dark:text-gray-400">
          <Link href="/pages/contact" className="hover:text-primary transition-colors">Contact</Link>
          <Link href="/pages/terms-and-conditions" className="hover:text-primary transition-colors">Terms and Conditions</Link>
          <Link href="/pages/privacy" className="hover:text-primary transition-colors">Privacy Policy</Link>
          <Link href="/pages/cookies" className="hover:text-primary transition-colors">Cookies</Link>
          <Link href="/pages/terms-of-service" className="hover:text-primary transition-colors">Terms of Service</Link>
        </div>

        {/* Footer Bottom */}
        <div className="border-t border-gray-300 dark:border-gray-600 pt-4 text-center text-xs text-gray-600 dark:text-gray-400">
          <p>© {currentYear} Tara Japan. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

"use client";

import React, { useEffect, useState } from "react";
import Widget from "./Widget";
import japanProTips from "@/lib/data/proTips";

const ProTips: React.FC = () => {
  const [currentTip, setCurrentTip] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);

      setTimeout(() => {
        setCurrentTip((prevTip) => (prevTip + 1) % japanProTips.length);
        setIsAnimating(false);
      }, 400);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const currentProTip = japanProTips[currentTip];

  return (
    <Widget
      icon="https://storage.googleapis.com/photo-portfolio/tara-japan/pro-tip.png"
      title="Japan Pro Tip"
      subtitle="Click below to learn more"
      content={
        <div className="px-4 line-clamp-3 mx-2">
          <a
            href={currentProTip.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`block text-sm text-gray-600 text-start underline transition-opacity duration-300 ${isAnimating ? "opacity-0" : "opacity-100"
              } hover:text-primary`}
          >
            {currentProTip.fact}
          </a>
        </div>
      }
    />
  );
};

export default ProTips;

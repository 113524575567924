const japanProTips = [
  {
    fact: "💡 Don't limit yourself to the ground floor! Hidden gems like great restaurants and shops often exist on upper floors or basements.",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/hidden-gems.png",
    url: "https://www.japan.travel/en/uk/inspiration/how-to-use-japanese-convenience-stores/",
  },
  {
    fact: "💡 Try Gyomu Super and Niku no Hanamasa for budget-friendly bulk groceries. Perfect for longer stays or group trips!",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/groceries.png",
    url: "https://www.gyomusuper.jp/",
  },
  {
    fact: "💡 Explore local drugstores like Matsumoto Kiyoshi and Kokumin—they offer cheaper prices for snacks, drinks, and even skincare.",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/drugstore.png",
    url: "https://www.japan.travel/en/uk/inspiration/shopping-in-japan/",
  },
  {
    fact: "💡 Conbini bentos (ready-made meals) are lifesavers for budget travelers. Don't miss their seasonal and regional specialties.",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/konbini.png",
    url: "https://www.japan.travel/en/uk/inspiration/how-to-use-japanese-convenience-stores/",
  },
  {
    fact: "💡 Look out for Ekiben (station lunch boxes) at train stations. They feature delicious, region-specific meals worth trying!",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/ekiben.png",
    url: "https://www.japan.travel/en/uk/inspiration/ekiben-guide/",
  },
  {
    fact: "💡 Vending machines in Japan go beyond drinks! Find hot meals, ice cream, and even fresh produce in unique ones across the country.",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/vending-machine.png",
    url: "https://www.japan.travel/en/uk/inspiration/vending-machines-in-japan/",
  },
  {
    fact: "💡 Skip taxis—bikes are a great way to explore Japan. Many cities like Kyoto offer cheap rental services for tourists.",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/bike-rental.png",
    url: "https://www.japan.travel/en/uk/inspiration/japan-on-two-wheels/",
  },
  {
    fact: "💡 Stay hydrated without breaking the bank! Use Japan's public water fountains—they're clean, safe, and free.",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/water-fountain.png",
    url: "https://www.japan.travel/en/uk/inspiration/tips-for-travelers/",
  },
  {
    fact: "💡 At ramen shops, tickets are bought at vending machines. Use coins or cash, and press the button for your meal of choice.",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/ramen-ticket.png",
    url: "https://www.japan.travel/en/uk/inspiration/ramen-etiquette/",
  },
  {
    fact: "💡 Japan’s second-hand stores like Book-Off or Hard-Off are treasure troves for cheap books, games, and electronics.",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/secondhand-store.png",
    url: "https://www.japan.travel/en/uk/inspiration/shopping-in-japan/",
  },
  {
    fact: "💡 Visiting during winter? Look for heated toilet seats in public restrooms—Japan's tech never ceases to amaze!",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/heated-toilet.png",
    url: "https://www.japan.travel/en/uk/inspiration/japanese-toilet-guide/",
  },
  {
    fact: "💡 100-yen shops like Daiso and Seria have unique Japanese items—perfect for budget-friendly souvenirs and gifts.",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/100yen-shop.png",
    url: "https://www.japan.travel/en/uk/inspiration/japan-s-100-yen-shops/",
  },
  {
    fact: "💡 Try small izakayas (Japanese pubs) for authentic food and atmosphere. Look for places frequented by locals for the best experience.",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/izakaya.png",
    url: "https://www.japan.travel/en/uk/inspiration/japan-s-izakayas/",
  },
  {
    fact: "💡 Craving Filipino ingredients? Shops in Tokyo, Osaka, and Nagoya stock everything from snacks to traditional spices.",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/filipino-food.png",
    url: "https://tokyofilipino.com/shops/",
  },
  {
    fact: "💡 Heading to a festival? Wear a yukata! Many places rent them for the day, making for a memorable cultural experience.",
    image: "https://storage.googleapis.com/photo-portfolio/tara-japan/yukata.png",
    url: "https://www.japan.travel/en/uk/inspiration/japanese-festivals/",
  },
];

export default japanProTips;

"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";
import Widget from "./Widget";

const WeatherWidget = () => {
  const [weatherData, setWeatherData] = useState<{
    tokyo?: any;
    manila?: any;
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const fetchWeather = async () => {
    try {
      setIsLoading(true);

      const response = await fetch("/api/weather");
      if (!response.ok) {
        throw new Error("Failed to fetch weather data");
      }

      const data = await response.json();
      setWeatherData(data);
      setError("");
    } catch (err: any) {
      console.error(err);
      setError(err.message || "Failed to fetch weather data.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWeather();
  }, []);

  const renderWeatherContent = () => {
    if (isLoading) {
      return (
        <div className="flex items-center justify-center py-2">
          <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-primary"></div>
          <p className="ml-3 text-xs">Fetching weather data...</p>
        </div>
      );
    }

    if (error) {
      return <p className="text-xs text-red-500 text-center">{error}</p>;
    }

    return (
      <div>
        {/* Tokyo Weather */}
        <div className="flex justify-between items-center gap-2 mx-4">
          <div className="flex items-center gap-4">
            <Image
              src="https://storage.googleapis.com/photo-portfolio/tara-japan/japan-flag-2.png"
              alt="Japan Flag"
              width={30}
              height={30}
              className="rounded-full"
            />
            <div>
              <h3 className="font-semibold text-sm text-gray-800">Tokyo</h3>
              <p className="text-xs text-gray-600 capitalize">
                {weatherData.tokyo?.weather?.[0]?.description || "No description"}
              </p>
            </div>
          </div>
          <div className="flex items-center text-right gap-2">
            <p className="text-sm font-bold text-primary">
              {weatherData.tokyo?.main?.temp ? `${Math.round(weatherData.tokyo.main.temp)}°C` : "N/A"}
            </p>
            <Image
              src={
                weatherData.tokyo?.weather?.[0]?.icon
                  ? `https://openweathermap.org/img/wn/${weatherData.tokyo.weather[0].icon}@2x.png`
                  : "/default-weather-icon.png"
              }
              alt="Weather Icon"
              width={40}
              height={40}
            />
          </div>
        </div>

        {/* Manila Weather */}
        <div className="flex justify-between items-center gap-2 mx-4">
          <div className="flex items-center gap-4">
            <Image
              src="https://storage.googleapis.com/photo-portfolio/tara-japan/ph-flag-2.png"
              alt="Philippine Flag"
              width={30}
              height={30}
              className="rounded-full"
            />
            <div>
              <h3 className="font-semibold text-sm text-gray-800">Manila</h3>
              <p className="text-xs text-gray-600 capitalize">
                {weatherData.manila?.weather?.[0]?.description || "No description"}
              </p>
            </div>
          </div>
          <div className="flex items-center text-right gap-2">
            <p className="text-sm font-bold text-primary">
              {weatherData.manila?.main?.temp ? `${Math.round(weatherData.manila.main.temp)}°C` : "N/A"}
            </p>
            <Image
              src={
                weatherData.manila?.weather?.[0]?.icon
                  ? `https://openweathermap.org/img/wn/${weatherData.manila.weather[0].icon}@2x.png`
                  : "/default-weather-icon.png"
              }
              alt="Weather Icon"
              width={40}
              height={40}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Widget
      icon="https://storage.googleapis.com/photo-portfolio/tara-japan/weather.png"
      title="Tokyo vs Manila"
      subtitle={`${new Date().toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })}`}
      content={renderWeatherContent()}
    />
  );
};

export default WeatherWidget;

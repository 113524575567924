"use client";

import React from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import ExchangeRateWidget from "../../components/ExchangeRateWidget";
import WeatherWidget from "../../components/WeatherWidget";
import ProTips from "../../components/ProTipWidget";
import VisaWidget from "../../components/VisaWidget";
import NewsWidget from "../../components/NewsWidget";

const WidgetsSection: React.FC = () => {
  return (
    <main className="flex items-center justify-center">
      <section className="mt-4 md:mt-8">
        <ScrollArea className="container w-screen overflow-x-auto pb-4">
          <div className="flex w-max space-x-4 px-2 py-2">
            {/* Widgets */}
            <div className="shrink-0 w-[300px]">
              <ExchangeRateWidget />
            </div>
            <div className="shrink-0 w-[300px]">
              <WeatherWidget />
            </div>
            <div className="shrink-0 w-[300px]">
              <ProTips />
            </div>
            <div className="shrink-0 w-[300px]">
              <VisaWidget />
            </div>
            <div className="shrink-0 w-[300px]">
              <NewsWidget />
            </div>
          </div>
        </ScrollArea>
      </section>
    </main>
  );
};

export default WidgetsSection;

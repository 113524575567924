import { create } from "zustand";

interface UserState {
  id: string | null;
  username: string | null;
  avatar: string | null;
  email: string | null;
  loggedIn: boolean;
  setUser: (data: { id: string; username: string; avatar: string; email: string }) => void;
  clearUser: () => void;
}

export const useUserStore = create<UserState>((set) => ({
  id: null,
  username: null,
  avatar: null,
  email: null,
  loggedIn: false,
  setUser: (data) =>
    set({
      ...data,
      loggedIn: true,
    }),
  clearUser: () =>
    set({
      id: null,
      username: null,
      avatar: null,
      email: null,
      loggedIn: false,
    }),
}));

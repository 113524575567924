"use client";

import { Avatar } from "@radix-ui/react-avatar";
import Image from "next/image";
import { useUserStore } from "@/lib/store/userStore";
import { cn } from "@/lib/utils/cn";

interface UserAvatarProps {
  className?: string;
  size?: number;
  src?: string | null;
}

const DEFAULT_AVATAR_URL =
  "https://storage.googleapis.com/photo-portfolio/tara-japan/avatar/default.png";

const UserAvatar: React.FC<UserAvatarProps> = ({ className, size = 40, src }) => {
  const { avatar } = useUserStore();

  const avatarUrl = src || avatar || DEFAULT_AVATAR_URL;

  const sizeStyle = {
    width: `${size}px`,
    height: `${size}px`,
  };

  return (
    <Avatar
      className={cn(
        "relative flex items-center justify-center rounded-full bg-slate-50 border border-gray-300 shadow",
        className
      )}
      style={sizeStyle}
    >
      <Image
        src={avatarUrl}
        alt="User's profile picture"
        referrerPolicy="no-referrer"
        className="object-cover rounded-full"
        fill
        sizes={`${size}px`}
      />
    </Avatar>
  );
};

export default UserAvatar;

import Hero2 from "../components/Hero2";

const AboutTaraJapan: React.FC = () => {
  return (
    <main className="mt-4">
      <Hero2
        title="Tara, Discover Japan, Pinoy style!"
        subtitle="We are Pinoys helping fellow Filipinos navigate travel and life in Japan — less stress, more enjoyment, and a supportive community."
        button1Text="Read more"
        button1Url="/pages/about"
        imageSrc="https://storage.googleapis.com/photo-portfolio/tara-japan/about-tara-japan.png"
        imageAlt=""
        imagePosition="left"
      />

    </main>
  );
};

export default AboutTaraJapan;

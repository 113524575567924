"use client";

import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { TypographyH3 } from "./Typography/TypographyH3";
import { TypographyP } from "./Typography/TypographyP";
import { useToast } from "@/hooks/use-toast";

export default function NewsletterSignup() {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState<"idle" | "loading" | "success" | "error">("idle");
  const { toast } = useToast();

  const handleSubmit = async () => {
    if (!email) {
      toast({
        title: "Error",
        description: "Please enter a valid email address.",
        variant: "destructive",
      });
      setStatus("error");
      return;
    }

    setStatus("loading");

    try {
      const response = await fetch("/api/newsletter", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();

      if (response.ok) {
        setStatus("success");
        toast({
          title: "Success",
          description: "Thank you for subscribing! We will reach out to you shortly!",
        });
        setEmail(""); // Reset the input field
      } else {
        setStatus("error");
        toast({
          title: "Error",
          description: result.error || "Something went wrong. Please try again.",
          variant: "destructive",
        });
      }
    } catch (error) {
      console.error("Subscription error:", error);
      setStatus("error");
      toast({
        title: "Error",
        description: "An unexpected error occurred. Please try again later.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-4 text-center mx-auto">
      <TypographyH3 className="text-lg font-semibold">Join Tara Japan Community</TypographyH3>
      <TypographyP>
        We will share updates and useful information sa email mo.
        <br className="hidden sm:inline" />
        Promise, we will not spam you 😹
      </TypographyP>
      <div className="flex flex-col sm:flex-row items-center gap-2">
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full sm:w-auto flex-1"
        />
        <Button onClick={handleSubmit} className="primary mt-2 md:m-0" disabled={status === "loading"}>
          {status === "loading" ? "Submitting..." : "Receive Info"}
        </Button>
      </div>
    </div>
  );
}

"use client";

import HeroParallax from "../components/HeroParallax";

const LivingInJapanGuide: React.FC = () => {

  return (
    <main>
      <section>
        <HeroParallax
          title="Japan Life Hacks"
          subtitle="Life in Japan, Pinoy Style"
          buttonPrimary={{ text: "Explore", link: "/pages/visiting-japan" }}
          //buttonSecondary={{ text: "Learn More", link: "/learn" }}
          imageUrl="https://storage.googleapis.com/photo-portfolio/tara-japan/woman-waiting-for-train.jpg"
          contentPosition="lower-right"
        />
      </section>
    </main>
  );
};

export default LivingInJapanGuide;

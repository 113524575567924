import * as React from "react";

import { cn } from "@/lib/utils/cn";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    // Separate checkbox-specific props
    const inputProps =
      type === "checkbox"
        ? { checked: props.checked }
        : { value: props.value };

    return (
      <input
        ref={ref}
        type={type}
        {...inputProps}
        {...props}
        className={cn(
          type === "checkbox"
            ? "h-5 w-5 border-gray-300 bg-red-600 focus:ring-red-400 rounded"
            : "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
      />
    );
  }
);

Input.displayName = "Input";

export { Input };

import React from "react";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card";
import Image from "next/image";

interface WidgetProps {
  icon: string;
  title: string;
  subtitle?: string;
  content: React.ReactNode;
}

const Widget: React.FC<WidgetProps> = ({ icon, title, subtitle, content }) => {
  return (
    <Card className="relative mx-auto w-full max-w-xs p-2 bg-slate-50 shadow-md rounded-md h-[180px]">
      <CardHeader className="flex flex-col items-center mb-2">
        <div className="flex items-center gap-3">
          <Image
            src={icon}
            alt={`${title} Icon`}
            width={60}
            height={60}
          />
          <div>
            <CardTitle className="text-primary text-md">{title}</CardTitle>
            {subtitle && (
              <CardDescription className="text-gray-600 text-xs">
                {subtitle}
              </CardDescription>
            )}
          </div>
        </div>
      </CardHeader>
      <CardContent>{content}</CardContent>
    </Card>
  );
};

export default Widget;

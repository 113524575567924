type TypographyH4Props = {
  children: React.ReactNode;
  className?: string
};

export function TypographyH4({ children, className }: TypographyH4Props) {
  return (
    <h3 className={`scroll-m-20 md:text-xl text-l tracking-tight ${className || ''}`}>
      {children}
    </h3>
  );
}


import HeroParallax from "../components/HeroParallax";

const VisitingJapanGuide: React.FC = () => {
  return (
    <main className="mt-4">
      <HeroParallax
        title="Ready for Adventure?"
        subtitle="Plan your perfect trip to Japan today."
        buttonPrimary={{ text: "Explore", link: "/pages/visiting-japan" }}
        //buttonSecondary={{ text: "Learn More", link: "/learn" }}
        imageUrl="https://storage.googleapis.com/photo-portfolio/tara-japan/asakusa.jpg"
        contentPosition="lower-left"
      />

    </main>
  );
};

export default VisitingJapanGuide;

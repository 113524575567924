"use client";

import React, { useEffect, useState } from "react";
import UserAvatar from "./UserAvatar";
import { useUserStore } from "@/lib/store/userStore";
import { useToast } from "@/hooks/use-toast";
import { RegisterLink, LoginLink, LogoutLink } from "@kinde-oss/kinde-auth-nextjs";
import { useKindeAuth } from "@kinde-oss/kinde-auth-nextjs";
import Link from "next/link";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";


const DEFAULT_AVATAR_URL =
  "https://storage.googleapis.com/photo-portfolio/tara-japan/avatar/default.png";

const UserAccountNav: React.FC = () => {
  const { id, username, avatar, setUser, clearUser } = useUserStore(); // Include clearUser
  const { user } = useKindeAuth();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [welcomeShown, setWelcomeShown] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.id) {
        setIsLoading(true);
        try {
          const response = await fetch(`/api/user/${user.id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            cache: "no-store",
          });

          if (response.ok) {
            const data = await response.json();

            // Always prioritize the database avatar
            setUser({
              id: user.id,
              username: data.username || "Friend",
              avatar: data.avatar || DEFAULT_AVATAR_URL,
              email: data.email || null,
            });

            if (!welcomeShown) {
              const displayName = user?.given_name || data.username || "Friend";
              toast({
                title: `Welcome, ${displayName}!`,
                description: "Login successful",
              });
              setWelcomeShown(true);
            }
          } else {
            console.error("Failed to fetch user data:", await response.text());
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    if (user?.id) {
      fetchUserData();
    }
  }, [user, setUser, toast, welcomeShown]);

  useEffect(() => {
    if (!user?.id) {
      clearUser();
      toast({
        title: "Goodbye!",
        description: "You have been logged out successfully.",
      });
    }
  }, [user, clearUser, toast]);

  if (isLoading) {
    return <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-primary"></div>
      ;
  }

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div
            className="flex items-center justify-center rounded-full cursor-pointer"
            role="button"
            tabIndex={0}
          >
            <UserAvatar src={avatar} />
          </div>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="w-56">
          {id ? (
            <>
              <DropdownMenuLabel>
                👋こんにちは, {username || "Friend"}!
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem asChild>
                  <Link href="/pages/profile-page">My Profile</Link>
                </DropdownMenuItem>
                <DropdownMenuItem asChild>
                  <Link href="/pages/settings">Settings</Link>
                </DropdownMenuItem>
                <DropdownMenuItem asChild>
                  <LogoutLink>Sign Out</LogoutLink>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </>
          ) : (
            <>
              <DropdownMenuGroup>
                <DropdownMenuItem asChild>
                  <LoginLink>Sign In</LoginLink>
                </DropdownMenuItem>
                <DropdownMenuItem asChild>
                  <RegisterLink>Sign Up</RegisterLink>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default UserAccountNav;

import React, { useEffect, useState } from "react";
import { TypographyH1 } from "./Typography/TypographyH1";
import { TypographyH4 } from "./Typography/TypographyH4";
import { Button } from "@/components/ui/button";
import Link from "next/link";

interface HeroParallaxProps {
  title: React.ReactNode;
  subtitle?: string;
  buttonPrimary?: { text: string; link: string };
  buttonSecondary?: { text: string; link: string };
  imageUrl: string;
  contentPosition?: "lower-left" | "center" | "lower-right";
}

const HeroParallax: React.FC<HeroParallaxProps> = ({
  title,
  subtitle,
  buttonPrimary,
  buttonSecondary,
  imageUrl,
  contentPosition = "lower-left",
}) => {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => {
    setOffsetY(window.scrollY * 0.5);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Determine alignment classes based on contentPosition prop
  const alignmentClasses = {
    "lower-left": "bottom-14 left-6 md:pl-10 md:pb-6 text-left",
    center: "inset-0 flex flex-col items-center justify-center text-center",
    "lower-right": "bottom-14 right-6 md:pr-10 md:pb-6 text-start", // Apply text-start for lower-right
  }[contentPosition];

  return (
    <section className="relative h-[70vh] overflow-hidden">
      {/* Parallax Image */}
      <div
        className="absolute inset-0 -z-10"
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>

      {/* Overlay for text readability */}
      <div className="absolute inset-0 bg-black/30"></div>

      {/* Text Content */}
      <div className={`absolute z-10 max-w-md ${alignmentClasses}`}>
        {/* Title */}
        <TypographyH1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white leading-tight mb-2">
          {title}
        </TypographyH1>

        {/* Subtitle */}
        {subtitle && (
          <TypographyH4 className="text-sm sm:text-base text-white/80 mb-4 leading-relaxed">
            {subtitle}
          </TypographyH4>
        )}

        {/* Buttons */}
        <div
          className={`flex gap-4 flex-wrap ${contentPosition === "center"
            ? "justify-start"
            : buttonPrimary && !buttonSecondary
              ? "justify-start"
              : ""
            }`}
        >
          {buttonPrimary && (
            <Link href={buttonPrimary.link} passHref>
              <Button size="lg">{buttonPrimary.text}</Button>
            </Link>
          )}
          {buttonSecondary && (
            <Link href={buttonSecondary.link} passHref>
              <Button size="lg" variant="secondary">
                {buttonSecondary.text}
              </Button>
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default HeroParallax;

"use client";

import Link from "next/link";
import { Icons } from "./Icons";
import { Button } from "@/components/ui/button";
import { TypographyH3 } from "./Typography/TypographyH3";
import { TypographyP } from "./Typography/TypographyP";
import Container from "./Container";

export default function ContactInfo() {
  return (
    <Container className="space-y-4 text-center md:text-left mx-auto">
      <TypographyH3 className="font-semibold">Contact us</TypographyH3>

      <div>
        <TypographyP className="text-gray-600 dark:text-gray-400">
          Tokyo, Japan <br />
          Osaka, Japan <br />
          Manila, Philippines <br />
        </TypographyP>
      </div>

      <div className="flex items-center justify-center md:justify-start gap-2 text-gray-600 dark:text-gray-400">
        <Icons.phone />
        <TypographyP className="text-sm">+1 800 000 0000</TypographyP>
      </div>

      <Link href="/pages/contact" passHref>
        <Button variant="link" className="text-blue-500 p-0 hover:underline">
          Contact us
        </Button>
      </Link>
    </Container>
  );
}

export const useCdn = process.env.NODE_ENV === "production";

export const projectId =
  process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ||
  (process.env.NEXT_PUBLIC_SANITY_STUDIO_PROJECT_ID as string);

// Tokens
export const readToken = process.env.SANITY_API_READ_TOKEN;
export const writeToken = process.env.SANITY_API_WRITE_TOKEN;

export const dataset =
  process.env.SANITY_DATASET || ("production" as string);

export const apiVersion =
  process.env.NEXT_PUBLIC_SANITY_API_VERSION || "2023-03-25";

export const previewSecretId = process.env
  .SANITY_REVALIDATE_SECRET as string;

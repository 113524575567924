import React from "react";
import Image from "next/image";
import Link from "next/link";
import { TypographyH2 } from "./Typography/TypographyH2";
import { TypographyP } from "./Typography/TypographyP";
import { Button } from "@/components/ui/button";

type UnderConstructionProps = {
  message?: string;
  homeLink?: string;
};

const UnderConstruction: React.FC<UnderConstructionProps> = ({
  message = "Under construction pa 'tong page for now. Please check again later 🙏",
  homeLink = "/",
}) => {
  return (
    <div className="flex flex-col items-center justify-center text-center px-10">
      <div className="mb-6">
        <Image
          src="/img/sad-sushi.png"
          alt="Sad Sushi"
          width={160}
          height={160}
          className="mx-auto"
          priority
        />
      </div>
      <TypographyH2 className="text-2xl font-bold text-gray-800 mb-2">
        Gomen🙇
      </TypographyH2>
      <TypographyP className="text-gray-600 mb-4 mx-10">{message}</TypographyP>
      <Link href={homeLink} passHref className="mt-10">
        <Button className="transition primary">
          Go Back Home
        </Button>
      </Link>
    </div>
  );
};

export default UnderConstruction;

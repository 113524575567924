"use client";

import { createContext, useContext } from "react";
import Container from "./(website)/components/Container";
import { TypographyH2 } from "./(website)/components/Typography/TypographyH2";
import { TypographyP } from "./(website)/components/Typography/TypographyP";
import UnderConstruction from "./(website)/components/UnderConstruction";

export const NotFoundContext = createContext(false);

export default function NotFound(): JSX.Element {
  return (
    <NotFoundContext.Provider value={true}>
      <Container>
        <section className="text-center my-12 mx-4">
          <TypographyH2>404 - Page Not Found</TypographyH2>
          <TypographyP>
            We want to make sure we deliver quality contents.
          </TypographyP>
        </section>
        <UnderConstruction />
      </Container>
    </NotFoundContext.Provider>
  );
}

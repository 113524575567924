"use client";

import React from "react";
import Widget from "./Widget";
import Link from "next/link";
import Image from "next/image";

const VisaWidget = () => {
  return (
    <Widget
      icon="https://storage.googleapis.com/photo-portfolio/tara-japan/visa-calculator.png"
      title="Applying for a visa?"
      subtitle="Know your chances"
      content={
        <div className="flex items-center justify-center gap-4">
          {/* Image */}
          <Image
            src="https://storage.googleapis.com/photo-portfolio/tara-japan/super-sushi.png"
            alt="Visa Icon"
            width={50}
            height={50}
            className="rounded-md"
          />
          {/* Text */}
          <div className="text-left">
            <Link
              href="/pages/visa-approval-calculator"
              className="block text-sm underline hover:text-primary"
            >
              Check our Visa Approval <br /> Rate Calculator
            </Link>
          </div>
        </div>
      }

    />
  );
};

export default VisaWidget;
